* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.img-fundo-setor {
  position: absolute;
  height: 800px;
  left: -50px;
  top: -100px;
  z-index: -1;
  opacity: 100;
}

.square-shape2 {
  position: absolute;
  right: 150px;
  top: -60px;
  z-index: 20;
  
}

.grafico-pie {
  z-index: 10;
}

.chart-title-setores {
  font-size: 2.2rem;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  letter-spacing: -1px;
  margin: 0 auto 120px;
  font-family: 'rubikbold';
}

.container-setores {
  width: 100%;
  max-width: 1800px;
  justify-content: center;
  margin: 140px auto;
  position: relative;
  z-index: 1000;
}



@media only screen and (max-width: 900px) {
  .square-shape2 {
    display: none;
  }

  .img-fundo-setor {
    display: none;
  }
  .chart-title-setores {
    font-size: 35px;
    margin-top: 140px;
    text-align: center;
  }
  .grafico-pie {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .square-shape2 {
    display: none;
  }

  .img-fundo-setor {
    display: none;
  }
  .chart-title-setores {
    font-size: 35px;
    margin: 80px auto 0px;
    text-align: center;
  }

  .estiloBtn{
    margin-top: 0;
  }
}

@media only screen and (max-width: 425px) {
  .estiloBtn{
    width: 90vw;
    padding: 0;
  }
}

