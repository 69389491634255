.pesq-header {
    display: flex;
    width: 100%;
    position: relative;
}

.pesq-header span {
    display: flex;
    align-items: flex-end;
    padding: 20px;
    text-align: right;
    white-space: nowrap;
}
.pesq-header span.floating-value {
    position: absolute;
    right: 0;
    margin-right: 250px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}

.pesq-header span.floating-value span {
    padding: 0 20px;
}

@media screen and (max-width: 1000px) {
    .pesq-header span.floating-value {
        display: none;
    }
}

.pesq-header span.progress {
    min-width: 200px;
    justify-content: center;
}

.pesq-header span.positive {
    color: #408941;
    background-color: #ddf1da;
}

.pesq-header span.negative {
    color: #a4201d;
    background-color: #f4bfbe;
}

.pesq-header .title {
    width: 100%;
    text-align: left;
}

.pesq-content {
    flex-direction: column;
    padding: 20px;
    display: none;
    width: 100%;

}

.pesq-content.open {
    display: flex;
}

.pesq-content .description {
    font-style: italic;
    color: #4A5F68;
    font-size: 16px;
    line-height: 24px;
    background-color: #e8f4fa;
    border: 1px solid #e8f4fa;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px 25px;
}

.pesq {

}