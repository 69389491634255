.container-donations-list {
  width: 100%;
  max-width: 100%;
  height: auto;
  background: #fff;
  color: #222222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 200px auto 100px;
}

.donations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 auto;
  width: 80%;
  max-width: 1440px;
}

.donations-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.donations-top img {
  margin-top: -150px;
  margin-right: -100px;
  width: 180px;
}

.donations-top-title h2 {
  font-size: 2.2rem;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  letter-spacing: -1px;
  margin: 0 0 10px;
  font-family: 'rubikbold';
}

.donations-top-description {
  width: 60%;
  min-width: 55%;
  margin-bottom: 50px;
}

.donations-top-description p {
  font-size: 1.4rem;
  text-align: left;
  margin: 0;
  font-family: 'rubikregular';
}

.loading {
  width: 100%;
  position: absolute;
  margin: -30px auto;
  left: 0;
}

.loading img {
  width: 80px;
  margin: 0;
}

.donations-list {
  max-width: 100%;
  width: 100%;
  background: #F7F7F7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 30px 0;
  margin: 0 auto;
}

.lista {
  /* column-gap: 50px; */
  /* -moz-column-gap: 50px; */
  margin: 10px auto;
  width: 100%;
  /* columns: 50vh 2; */
  padding: 0 30px;
}

.donations-list p {
  text-align: left;
  font-size: 1rem;
  margin: 0;
  line-height: 28px;
  font-family: 'rubikregular';
  animation: aparecer .5s;
}

@keyframes aparecer {
  0% {opacity: 0;}
  100% { opacity: 1;}
}

p .donation {
  font-family: 'rubikbold';
}

.donation a {
  text-decoration: none;
  color: #222222;
}

.donation a:hover {
  text-decoration: underline;
}

.load-more {
  margin: 60px auto 10px;
  font-family: 'rubikbold';
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4C87B1;
}

.load-more .button {
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

@media only screen and (max-width: 1000px) {
  .donations-top-img img {
    margin-right: 0;
  }

  .donations-top-description {
    width: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .donations {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .container-donations-list {
    margin: 100px auto;
  }

  .donations-list {
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }

  .donations-top-img {
    display: none;
  }

  .donations-top-text {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .donations {
    width: 95%;
  }

  .lista {
    columns: 100vh 1;
    column-gap: 0px;
    -moz-column-gap: 0px;
    margin: 0;
    width: 100%;
    padding: 5px 0;
  }

  .donations-list p {
    padding: 10px 0;
  }
}

.lista {
  padding: 0 20px;
  height: 500px;
  overflow: auto;
}

.list-item {
  width: 100%;
  display: grid;
  text-align: left;
  grid-template-columns: 50% 25% 25%;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 3px solid #408ec5;
  font-size: 1rem;
}