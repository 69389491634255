.container-menu {
    width: 100%;
    height: 110px;
    background: #F3F3F3;
    margin: 0;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 2000;
    box-shadow: 0 0 3px 0 #979797;
}

.menu {
    width: 80%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-title {
    display: flex;
    align-items: center;
}

.menu-title img {
    width: 16rem;
}

.menu-title h1 {
    font-size: 1.4rem;
    font-family: 'rubikmedium';
    min-width: 120px;
}

.menu-language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
}

.menu-language a {
    font-size: 1.4rem;
    font-family: 'rubikmedium';
    color: #222222;
    text-decoration: none;
}

.menu-language a:hover {
    color: #4C87B1;
    border-bottom: 3px solid #4C87B1;
}

.menu-language .active {
    color: #4C87B1;
    border-bottom: 3px solid #4C87B1;
}

@media only screen and (max-width: 768px) {
    .menu {
        width: 95%;
    }
}

@media only screen and (max-width: 540px) {
    .container-menu {
        width: 100%;
    }
    
    .menu {
        width: 95%;
    }

    .menu-language {
        width: 80px;
        margin-right: 10px;
    }
    
}

@media only screen and (max-width: 450px) { 
    .menu {
        width: 95%;
    }

    .menu-title {
        width: 60%;
    }

    .menu-title img {
        width: 120px;
    }
    .menu-title h1 {
        font-size: 1.1rem;
        font-family: 'rubikmedium';
        min-width: 120px;
    }
}

@media only screen and (max-width: 320px) {
    .container-menu {
        /* border: solid red;*/
        width: 100%; 
    }

    .menu-title {
        flex-direction: column;
    }

    .menu-language {
        display: flex;
        flex-direction: column;
        height: 70px;
    }

    /* .menu-language a {
        margin-top: 30px;
        border: solid green;
    } */
}

.menu-middle {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.menu-title {
    width: 100%;
}

.menu-button {
    font-size: 12px;
    border: 1px solid;
    padding: 10px;
    margin: 0 20px;
    cursor: pointer;
}