/*-------Reset------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*----------------------------*/
/*-----------Font-----------------*/
/*----------------------------*/
h4 {
  color: #fff;
  margin-top: 0.313rem;
  font-size: 0.875rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-top: 20px;
}

h2 {
  font-size: 27px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin: 8px 0 17px 0;
  text-align: center;
}

/*----------------------------*/
/*-----------Banner----------------*/
/*----------------------------*/
/*-----------Ids-----------------*/
#banner {
  /* margin-top: 8rem; */
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-bottom: 100px;
}

#doacoes,
#doadores,
#money-campaign {
  color: #4c87b1;
  font-weight: 500;
  font-size: 4rem;
  font-family: "Rubik", sans-serif;
}

#img-center > img {
  width: 50%;
  padding-top: 0.313rem;
}

#nvd {
  left: 0;
  width: 15%;
  height: 15%;
}

#nve {
  max-width: 15%;
  height: 15%;
}

#nve img {
  width: 200px;
  height: 200px;
}

#nv1{
  width: 25vw;
}
#money > #doa,
#hand > #hand-p {
  height: 4.688rem;
  width: 4.688rem;
  margin: 0 auto;
}

.total-campanhas {
  width: 100%;
}

#money,
#hand {
  height: 280px;
}

/*----------Class------------------*/
.banner-container > #banner-content,
#img-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 68px;
}

#img-center {
  padding-top: 0;
}
/*----------------------------*/
/*-----------Sections-cards----------------*/
/*----------------------------*/

/*-----------Class-----------------*/
.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #262626;
}

.dados {
  padding: 10px 0 5px 0;
  color: #408ec5;
  font-size: 1.875;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.section-container {
  width: 20%;
  min-height: 8rem;
  text-align: start;
  margin: 1rem 0.48% 1rem 0.5%;
  border-radius: 5px;
  background-color: #2c2b32;
  padding: 0 0.7% 0 2%;
  /* height: 203px; */
}

/*----------------------------*/
/*-----------Mobil----------------*/
/*----------------------------*/
/*-----------ids-----------------*/

@media only screen and (max-width: 425px) {
  h4 {
    font-size: 1.125rem;
  }

  h2 {
    font-size: 1.58rem;
  }

  /*-----------ids-----------------*/
  #banner {
    width: 100%;
    height: auto;
  }

  #doacoes,
  #doadores, #money-campaign {
    font-size: 2rem;
    text-align: center;
  }

  #img-center > img {
    width: 100%;
  }

  #hand,
  #money {
    margin: 0.625;
    padding: 2px;
  }

  #banner-content {
    padding: 10px;
  }

  /*----------Class------------------*/
  .banner-container {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .section-container {
    width: 100%;
  }

  .container {
    width: 100%;
    height: auto;
    justify-content: center;
  }

  .dados {
    font-size: 2.5rem;
  }

  #nvd {
    margin-left: 15vw;
  }

  #nv1 {
    width: 70vw;
    margin: 0 auto;
  }

  #nv2 {
    width: 90vw;
    margin: 0 auto;
  }
}

/*----------Tablet------------------*/
@media only screen and (max-width: 768px) {
  .container,
  #banner {
    width: 100%;
    margin: auto;
  }

  .section-container {
    margin: 20px 20px 0 20px;
    padding: 10px 1px 0 15px;
  }

  .container {
    padding-left: 40px;
    padding-right: 40px;
  }

  #hand,
  #money {
    margin-top: 1.25rem;
    width: 100%;
  }
  #nvd {
    margin-left: 25vw;
  }

  #nve {
    margin-left: 25vw;
  }
}

/*-----------Laptop----------------*/

@media only screen and (max-width: 1024px) {
  .container,
  #banner {
    width: 100%;
    margin: auto;
    height: auto;
  }

  #banner {
    margin-top: 60px;
  }

  h4 {
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }

  .dados {
    font-size: 1.125rem;
  }

  .section-container {
    width: 15rem;
    height: 8rem;
    margin: 10px 4px 0 4px;
    padding-right: 1px;
  }
}

@media only screen and (max-width: 425px) {
  #nvd {
    margin-left: 15vw;
  }

  #nve {
    margin-left: 0vw;
  }
}
