.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
button,
textarea {
  font: 400 1.6rem "rubikregular";
}

.estiloBtn{
  width: 12rem;
  height: 4rem;
  margin: 60px auto;
  transition: ease .2s;

  border: none;
  background-color: #4C87B1;
  border-radius: 5px;
  
  font-family: 'rubikmedium';
  word-spacing: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  
}

.estiloBtn:hover {
  background-color: #4c94b1;
}




.yearButtons {
  margin-top: 8.2rem;
}

.yearButtons a {
  padding: 5px;
  border: 1px solid black;
  font-size: 16px;
  margin: 10px;
  text-decoration: none;
  color: black;
}

.masterLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background-color: rgba(0,0,0,0.5);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
}