* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.section-chart-container {
  width: 100%;
  max-width: 1920px;
  color: #222222;
  margin: 0 auto;
}

/* Coloca os elementos lado a lado  */
.chart-indicators {
  display: flex;
}

/* 
title dos dois itens  */
.indicators-title,
.chart-title {
  font-size: 2.2rem;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  padding: 10px;
  letter-spacing: -1px;
  margin: 50px auto 80px;
  font-family: 'rubikbold';
}

.section-chart-container .chart-title {
  margin-left: 0;
}

/* Estilos do item 
indicators ---------------------  */
.div-chart {
  background: #f7f7f7;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  border-radius: 5px;
  padding-bottom: 50px;
}

.chart {
  margin-right: 30px;
}

/* Posiciona o item  */
.div-indicators {
  width: 50%;
  padding-right: 10%;
  padding-left: 130px;
}

.indicators-subitem {
  display: flex;
  margin-bottom: 130px;
}

.linhas {
  display: block;
  content: "";
  width: 40vw;
  height: 2px;
  background: #e1dfdf;
  position: relative;
  top: -70px;
  margin: 0 auto;
}

.img-hand-money {
  position: relative;
  left: -30px;
}

.section-chart-container h3,
.span-h3 {
  font-family: "rubikregular";
  font-size: 1.8rem;
  line-height: 44px;
  margin-bottom: 16px;
  text-align: left;
}

.span-h3 {
  font-family: "rubikbold";
  font-size: 34px;
}

.section-chart-container span {
  color: #4C87B1;
  font-family: "rubikbold";
}

.section-chart-container p span {
  color: #4C87B1;
  font-family: "rubikbold";
  line-height: 0px;
}

.section-chart-container p {
  text-align: left;
}

.indicators-subitem-doadores {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.indicators-subitem-doadores p {
  line-height: 27px;
  letter-spacing: 0px;
  margin-bottom: 13px;
  font-family: "rubikregular";
  font-size: 18px;
}

.total-doadores-div,
.biggest-donor {
  width: 14.5vw;
}

.biggest-live p {
  width: 100%;
}

/* Botão ver mais */

.chart-indicators-button {
  font-family: "rubikmedium";
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 25px 50px; */
  background: #4C87B1 0% 0% no-repeat padding-box;
  border-radius: 100px;
  border: none;
  font-size: 24px;
  letter-spacing: 2px;
  color: #ffffff;
  margin: 100px auto 0;
  text-transform: uppercase;
  cursor: pointer;
  width: 335px;
  height: 80px;
  max-width: 320px;
}

.chart-indicators-button:hover {
  box-shadow: 0 0 5px 1px #4C87B1;
}

/* Ajustes para responsivo  */
/* @media screen and (max-width: 1920px) {
  .indicators-subitem-doadores p:nth-child(2) {
    margin-right: 100px ; 
  }
} */

@media screen and (max-width: 1440px) {
  .div-indicators {
    padding-left: 100px;
  }
  .div-chart {
    padding-left: 5%;
  }
  .div-indicators {
    padding-right: 3%;
    padding-left: 5%;
  }
  .indicators-subitem-doadores p {
    margin-right: 6vw;
  }

  .total-doadores-div,
  .biggest-donor {
    width: 19vw;
  }
  .biggest-donor p {
    width: 19vw;
  }
}

@media screen and (max-width: 1024px) {
  .section-chart-container {
    width: 100%;
  }

  .div-indicators {
    padding-left: 100px;
  }
  .div-chart {
    padding-left: 5%;
  }
  .div-indicators {
    padding-right: 3%;
    padding-left: 5%;
  }
  .indicators-subitem-doadores p {
    margin-right: 0;
  }
  .img-hand-money {
    width: 5vw;
  }

  .total-doadores-div,
  .biggest-donor {
    width: 20vw;
  }
}

@media screen and (max-width: 768px) {
  .section-chart-container {
    width: 100%;
  }

  .section-chart-container .indicators-title,
  .section-chart-container .chart-title {
    font-size: 2rem;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: left;
  }

  .section-chart-container .indicators-title {
    margin-bottom: 70px;
  }
  .div-chart {
    padding-left: 10px;
  }

  .div-indicators {
    padding-right: 10px;
    padding-left: 15px;
  }

  .section-chart-container h3,
  .span-h3 {
    font-size: 25px;
  }

  .img-hand-money {
    display: none;
  }

  .indicators-subitem {
    margin-bottom: 80px;
  }

  .total-doadores {
    max-width: 165px;
  }

  .indicators-subitem-doadores p {
    margin-right: 5px;
  }

  .total-doadores-div p {
    width: 10.5vw;
  }
  .biggest-donor p {
    width: 25vw;
  }

  .linhas {
    width: 40vw;
    top: -45px;
  }

  .chart {
    margin-right: 0;
  }
}



@media screen and (max-width: 425px) {
  .section-chart-container .indicators-title,
  .section-chart-container .chart-title {
    font-size: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .chart-indicators {
    flex-wrap: wrap;
  }

  .div-chart,
  .div-indicators {
    width: 100%;
    padding-right: 3vw;
    padding-left: 3vw;
  }

  .chart-indicators-button {
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .total-doadores-div,
  .biggest-donor {
    width: 50%;
  }
  .total-doadores-div p {
    width: 49vw;
  }
  .biggest-donor p {
    width: 45vw;
  }
  .linhas {
    width: 80vw;
    top: -45px;
  }


}

@media screen and (max-width: 375px) {
  .section-chart-container .indicators-title,
  .section-chart-container .chart-title {
    font-size: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .section-chart-container h3,
  .span-h3 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .total-doadores-div p {
    width: 30vw;
  }
  .biggest-donor p {
    width: 51vw;
  }
  .linhas {
    width: 90vw;
    top: -45px;
  }
}

@media screen and (max-width: 425px) {
  .section-chart-container {
    width: 100%;
  }
  .div-indicators,
  .div-chart {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section-chart-container h3,
  .span-h3 {
    text-align: center;
    max-width: 100vw;
    margin-bottom: 5px;
  }

  .indicators-subitem p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .indicators-subitem-doadores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .indicators-subitem div{
    width: 90vw;
  }
  .span-h3 {
    text-align: center;
  }
  .chart-indicators-button {
    width: 90vw;
  }

  .biggest-donor p {
    width: 100vw;
    text-align: center;
  }

  .total-doadores-div,
  .biggest-donor {
    width: 100%;
  }
  .total-doadores-div p {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 14px;
    text-align: center;
  }
  .biggest-donor p {
    width: 100%;
  }

  .biggest-live p {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 14px;
  }
}