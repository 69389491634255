.press {
  margin: 100px 0;
}

.press h2 {
  font-size: 2.2rem;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  padding: 10px;
  letter-spacing: -1px;
  margin: 50px auto 0;
  font-family: 'rubikbold';
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  max-width: 1240px;
  margin: 20px auto 0;
}

.list__item {
  border: none;
  background-color: #ffffff;
  border: 1px solid #F7F7F7;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px #4C87B1;
  
  width: 15rem;
  height: 8rem;
  padding: 5px;
  transition: 0.3s;
  color: #4C87B1;
  margin: 20px 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  animation: sobe .5s;
}

.list__item:hover {
  box-shadow: 0px 6px 6px 0px #4C87B1;
  margin-top: 14px;
}


@keyframes sobe {
  0% {opacity: 0; margin-top: 0px;}
  100% { opacity: 1; margin-top: 20px;}
}

.press .list__item p:first-child {
  font-size: 0.8rem;
  margin: 10px 0;
  font-family: "rubikmedium";
}

.press .list__item p {
  font-size: 1rem;
}

.press .button {
  width: 18rem;
  margin: 20px auto;
  font-family: 'rubikbold';
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4C87B1;
  cursor: pointer;
}

