.blocodois {
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
}
.container-fundo {
  width: 100%;
  height: 800px;
  background: #ffffff;
  font-family: 'Rubik', sans-serif;
  max-width: 1440px;
  margin: 0 auto;
}

.fundo-sombra img {
  float: left;
  position: absolute;
  width: 998px;
  height: 900px;
  overflow: visible;
  left: -370px;
  top: 0px;
}

.imgdecorativo img {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.circulo img {
  position: absolute;
  right: 200px;
  margin-top: 37px;
  width: 240px;
  height: 240px;
}

.quadrado {
  position: absolute;
  width: 41%;
  height: 45%;
  background: #f7f7f7;
  top: 0;
  right: 0;
}
.container-texto {
  position: absolute;
  width: 100%;
  top: 0;
}

.text__title h1 {
  font-size: 30px;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  padding: 10px;
  letter-spacing: -1px;
  margin: 200px auto 0;
  font-family: 'rubikbold';
  max-width: 1440px;
  position: relative;
  cursor: pointer;
}

.text__title h1.closed::after {
  content: "˅";
  font-family: monospace;
  margin-left: 20px;
  position: absolute;
  top: 15px;
  margin-left: 20px;
}

.text__title h1:not(.closed)::after {
  content: '˄';
  margin-left: 20px;
  font-family: monospace;
  margin-left: 20px;
  position: absolute;
  top: 15px;
}

.text__content {
  width: 80%;
  max-width: 1440px;
  margin: 50px auto;
  padding: 10px;
}

.text__content p {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 40px;
  white-space: break-spaces;
}

.text__content .email {
  color: #4c87b1;
}

.text__content .email:hover {
  text-decoration: underline;
}

.text__content .link {
  color: #4c87b1;
}

.text__content .link:hover {
  text-decoration: underline;
}

/* Segunda parte */

.container-final {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 70%;
}

.container-final img:first-child {
  margin-bottom: 50px;
}

.doar img {
  width: 240px;
}
.qrcode img {
  width: 180px;
}


/* Responsivo  */

@media (max-width: 1024px) {
  .imgdecorativo img {
    bottom: -30px;
    left: 10px;
    width: 200px;
    height: 200px;
  }

  .circulo img {
    right: 100px;
  }

  .text__title h1 {
    margin-top: 170px;
  }
}

@media (max-width: 768px) {
  .imgdecorativo img,
  .circulo img,
  .fundo-sombra img,
  .quadrado {
    display: none;
  }

  .text__title {
    width: 90%;
    margin: 40px auto 10px;
  }

  .text__title h1 {
    width: 100%;
    font-size: 2.2rem;
    margin: 0;
  }

  .text__content {
    width: 90%;
  }

  .text__content p {
    font-size: 24px;
  }

  .container-fundo {
    height: 950px;
  }
}

@media screen and (max-width: 600px) {
  .container-fundo {
    height: 1220px;
  }
}

@media screen and (max-width: 450px) {
  .container-fundo {
    height: 1650px;
  }

  .text__title h1 {
    font-size: 2rem;
    margin: 50px auto 0;
  }

  .text__content p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 425px) {
  .container-fundo {
    height: 1800px;
  }
}

@media screen and (max-width: 375px) {
  .container-fundo {
    height: 2100px;
  }
}

@media screen and (max-width: 300px) {
  .container-fundo {
    height: 2400px;
  }
}

.invisible {
  display: none;
}

