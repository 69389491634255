.container-footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    background: #F7F7F7;
    margin-top: 100px;
}

.partner-title h3 {
  font-size: 2.2rem;
  text-align: left;
  color: #222222;
  width: 80%;
  max-width: 1440px;
  /* padding: 10px; */
  letter-spacing: -1px;
  margin: 0 auto;
  font-family: 'rubikbold';
}

.partner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  margin: 0 auto 40px;
}

.partner img{
  height: 60px;
  margin: 50px;
}


.partner-one img,
.partner-two img,
.partner-three img,
.partner-four img {
    width: 200px;
    margin: 30px auto;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #4C87B1;
  padding: 70px 0;
}

.footer-first {
  display: flex;
  margin: 0 auto 50px;
}

.footer-first-promocao,
.footer-first-realizacao,
.footer-first-imprensa,
.footer-first-qrcode {
  width: 200px;
  display: flex;
  flex-direction: column;
  margin: 0 50px;
}

.footer-title h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 5px;
  margin: 10px auto;
  text-align: center;
}

.footer-img-promocao,
.footer-img-realizacao {
  width: 200px;
  height: 150px;
  margin-top: 10px;
}

.footer-img-qrcode {
  width: auto;
  height: auto;
  margin-top: 10px;
}

.footer-img-promocao img {
  width: 200px;
}

.footer-img-realizacao img {
  width: 200px;
}

.footer-img-qrcode img {
  width: 100px;
}

.footer-description {
  margin-top: 10px;
}

.footer-description p {
  margin: 15px 0 10px 0;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-family: "rubikregular";
}

.footer-description a {
  text-decoration: none;
}

.footer-description-name {
  font-style: italic;
}

.footer-link span {
  font-family: "rubikregular";
  color: #fff;
  font-size: 12px;
  font-style: italic;
  text-align: center;
  text-decoration: none;
}

.footer-link a:hover {
  text-decoration: underline;
}

.footer-link p {
  margin-top: 20px;
}

.footer-secondary {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    justify-items: center;
}

.footer-secondary-compartilhar {
  width: 25%;
  margin: auto 0;
}

.footer-secondary-compartilhar a {
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.footer-secondary-compartilhar img {
  width: 15px;
  margin-right: 10px;
}

.footer-secondary-compartilhar h4 {
    color: #FFF;
    text-transform: lowercase;
    font-size: 12px;
    letter-spacing: 3.8px;
    font-family: "rubikmedium";
    margin: auto 0;
}

.footer-secondary-sociais {
  display: flex;
  justify-content: center;
  width: 50%;
}

.footer-secondary-sociais a {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.footer-secondary-sociais img {
  width: 30px;
  margin: 0 5px;
}

@media only screen and (max-width: 1125px) {
  .footer-first {
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .footer-first-promocao,
  .footer-first-realizacao,
  .footer-first-imprenssa,
  .footer-first-qrcode {
    margin: 0 30px;
  }
}

@media only screen and (max-width: 1020px) {
  .footer-first {
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .footer-first-promocao,
  .footer-first-realizacao,
  .footer-first-imprenssa,
  .footer-first-qrcode {
    margin: 0 10px;
  }

  .footer-secondary {
    width: 90%;
  }
}

@media only screen and (max-width: 860px) {
  .footer-first {
    width: 95%;
  }

  .footer-first-promocao,
  .footer-first-realizacao,
  .footer-first-imprensa,
  .footer-first-qrcode {
    margin: 0 10px;
  }

  .footer-secondary {
    width: 90%;
  }
}

@media only screen and (max-width: 810px) {
  .container {
    flex-wrap: wrap;
    height: auto;
    padding: 30px 0;
  }

  .footer-first {
    flex-wrap: wrap;
    width: 95%;
  }

  .footer-secondary {
    width: 95%;
  }
}

@media only screen and (max-width: 620px) {
  .footer-secondary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-secondary-compartilhar,
  .footer-secondary-sociais {
    margin: 20px 0;
  }

  .partner-title {
    width: 90%;
    margin: 0 auto;
  }

  .partner-title h3 {
    text-align: center;
  }

  .partner div {
    width: 100%;
  }
}

@media only screen and (max-width: 460px) {
  .footer-first-promocao,
  .footer-first-realizacao,
  .footer-first-imprensa {
    margin: 10px 10px;
  }

    .footer-first-promocao,
    .footer-first-realizacao,
    .footer-first-imprensa {
        margin: 10px 10px;
    }

    .footer-first-qrcode {
        margin-top: 30px;
    }

    .partner {
        flex-direction: column;
    }

}
