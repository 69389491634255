* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.charts-donations {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
}

.chart-item{
  margin: 30px 5px;
  width: 38vw;
}

.chart-item:last-child{
  margin-bottom: 50px;
}

@media only screen and (max-width: 1368px) {
  .chart-item{
    margin: 30px 5px;
    width: 30vw;
  }
}

@media only screen and (max-width: 1024px) {
  .chart-item{
    margin: 10px 5px;
    /* widows: 40vw; */
  }
}

@media only screen and (max-width: 768px) {
  .chart-item{
    margin: 20px;
    width: 80vw;
  }  
  .estiloBtn {
    margin-top: 0;
  }
}

@media only screen and (max-width: 425px) {
  .charts-donations {
    width: 90%;
  }
  .estiloBtn {
    width: 90vw;
    padding: 0;
  }
}

@media only screen and (max-width: 375px) {
.chart-item{
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}
}

@media only screen and (max-width: 320px) {
  .charts-donations {
    width: 95%;
  }
}
