* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.chart-title-setores {
  font-size: 50px;
  margin-top: 140px;
  margin-bottom: 86px;
  font-family: "rubikbold";
  text-align: left;
  color: #222222;
  margin-left: 140px;
}

.grafico {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  .chart-title-setores {
    font-size: 35px;
    margin-top: 140px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .square-shape2 {
    display: none;
  }

  .img-fundo-setor {
    display: none;
  }
  .chart-title-setores {
    font-size: 35px;
    margin: 80px auto 0px;
    text-align: center;
  }

  .estiloBtn {
    margin-top: 0;
  }
}

@media only screen and (max-width: 425px) {
  .estiloBtn {
    width: 90vw;
    padding: 0;
  }
}
